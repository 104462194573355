.page-opestamparia #main {
    max-width: 100%;
}
.page-opestamparia .ordens-container table td {
    white-space: nowrap;
}
.page-opestamparia .filtro-container {
    border: solid 1px #9c9c9c;
    border-radius: 5px;
    margin-bottom: 1rem;
    padding: 20px 20px 10px;
}
.page-opestamparia .totalizadores-container {
    padding: 5px 0;
}
.page-opestamparia .totalizadores-container .titulo {
    display: inline-block;
    margin-right: 25px;
}
.page-opestamparia .totalizadores-container .item {
    border: 1px solid var(--form-components-border);
    border-radius: .25rem;
    display: inline-block;
    margin-right: 10px;
    padding: 5px 15px;
}
.page-opestamparia #footer-print {
    display: none !important;
}