.filtrodecliente-container {
    border: solid 1px #9c9c9c;
    border-radius: 5px;
    margin-bottom: 1rem;
    padding: 20px 20px 10px;
}
.filtrodecliente-container .form-actions {
    text-align: right;
}
.filtrodecliente-container .form-actions > button:first-of-type {
    margin-right: 5px;
}

@media (min-width: 992px) {
    .filtrodecliente-container .form-actions {
        margin-left: 15px;
    }
    .filtrodecliente-container > form {
        display: flex;
        flex-wrap: wrap;
    }
    .filtrodecliente-container > form > :last-child {
        margin-right: 0;
    }
}