#routeContent .print {
    font-size: 10pt;
    -webkit-print-color-adjust: exact; 
}
#routeContent .print .page-title {
    font-size: 16pt;
    font-weight: bold;
    text-align: center;
}
#routeContent .print table {
    width: 100%;
}
#routeContent .print table caption {
    caption-side: top;
    color: #000;
    font-weight: bold;
}
#routeContent .print table th {
    background-color: #d8d8d8;
    border: solid 1px #000;
    padding: 3px 10px;
}
#routeContent .print table tr {
    border: solid 1px #000;
}
#routeContent .print table td {
    border: solid 1px #000;
    padding: 3px 10px;
}
#routeContent .print table td.label {
    background-color: #d8d8d8;
    font-weight: bold;
}
#routeContent .print .maquina {
    width: calc(50% - 8px);
}
#routeContent .print .eventos-container {
    display: grid;
    grid-template-areas: "col col";
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 15px;
}
#routeContent .print .eventos-container table {
    margin-bottom: 0;
}
#routeContent .print .eventos-container .eventos thead {
    line-height: 1;
}
#routeContent .print .eventos-container .eventos td {
    min-width: 0.5cm;
    padding: 0;
    height: 31px;
}
#routeContent .print .tipologia-container {
    color: #424242;
    display: grid;
    font-size: 10pt;
    grid-template-areas: "col col";
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 15px;
}
#routeContent .print .tipologia-container .grupo .titulo {
    background-color: #e4e4e4;
    font-weight: bold;
    font-size: 9pt;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: center;
}
#routeContent .print .tipologia-container ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}
#routeContent .print .tipologia-container li {
    margin-bottom: 5px;
    padding-right: 15px;
    width: 50%;
}
#routeContent .print .tipologia-container .sigla {
    border: dashed 1px #585858;
    display: inline-block;
    margin-right: 5px;
    padding: 0 4px;
    text-align: center;
    width: 40px;
}