.page-dashboard #main {
    max-width: 100%;
}
.page-dashboard .dashboard-title {
    margin-bottom: 2rem;
}
.page-dashboard .dashboard-chart-description {
    font-size: .9rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: center;
}
.page-dashboard #dashboardTabs .nav-link button {
    border: none;
    line-height: 0;
    margin-left: 4px;
    outline: none;
    padding: 0;
}
.page-dashboard #dashboardTabs .nav-link button:hover {
    color: #f00;
}

@media (min-width: 576px) {
    
}