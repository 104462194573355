.login-container {
    border: solid 1px #ccc;
    border-radius: 10px;
    max-width: 390px;
    margin: auto;
    padding: 40px;
}
.login-container h2 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    text-align: center;
}
.login-container button {
    width: 100%;
}