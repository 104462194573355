#footer {
    margin-top: 80px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 28px;
    position: relative;
}
#footer a {
    color: var(--gray);
    font-weight: 100;
    font-size: 14px;
}
#footer .links {
    list-style: none;
    padding: 0;
}
#footer .links i {
    margin-right: 7px;
}
#footer .logo {
    width: 120px;
}
#footer .faixa {
    background-color: var(--primary1);
    bottom: 0;
    height: 25px;
    position: absolute;
    width: 100%;
}
#footer-content {
    min-height: 99px;
    text-align: center;
}
#footer-content .col-logo {
    margin-bottom: 20px;
    order: 1;
}
#footer-content .col-links {
    margin-bottom: 10px;
    order: 2;
}
@media(min-width: 576px) {
    #footer-content {
        text-align: left;
    }
    #footer-content .col-logo {
        order: 2;
        text-align: right;
    }
    #footer-content .col-links {
        order: 1;
    }
}

#footer-print {
    position: fixed;
    bottom: 0;
    text-align: right;
    opacity: .7;
}