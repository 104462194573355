.ordemproducao-form {
    padding-bottom: 50px;
}
.ordemproducao-form fieldset {
    border-bottom: dashed 1px #ababab;
    padding: 30px 0;
}
.ordemproducao-form fieldset:first-of-type {
    padding-top: 0;
}
.ordemproducao-form fieldset:last-of-type {
    border-bottom: none;
}
.ordemproducao-form .form-group-fluid {
    display: flex;
    flex-wrap: wrap;
}
.ordemproducao-form .form-group-fluid > div {
    flex: 0 0 260px;
    margin-right: 15px;
}
.ordemproducao-form .form-group {
    position: relative;
}
.ordemproducao-form .form-group.-box {
    border: solid 1px #8b8b8b;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 6px;
}
.ordemproducao-form .form-group.-box--withfooter {
    padding-bottom: 75px;
}
.ordemproducao-form .form-group.-box > .footer {
    background-color: #d8d8d8;
    border-top: solid 1px #8b8b8b;
    bottom: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    left: 0;
    padding: 10px;
    position: absolute;
    width: 100%;
}
.ordemproducao-form .form-group.-box .menu-container {
    display: flex;
    flex: 1;
    justify-content: space-around;
}
.ordemproducao-form .statusop-select {
    font-weight: bold;
    cursor: pointer;
}
.ordemproducao-form .statusop-select.status-aberta {
    border-color: var(--statusop-aberta-color);
    color: var(--statusop-aberta-color);
}
.ordemproducao-form .statusop-select.status-producao {
    border-color: var(--statusop-producao-color);
    color: var(--statusop-producao-color);
}
.ordemproducao-form .statusop-select.status-fechada {
    border-color: var(--statusop-fechada-color);
    color: var(--statusop-fechada-color);
}
.ordemproducao-form .statusop-select.status-finalizada {
    border-color: var(--statusop-finalizada-color);
    color: var(--statusop-finalizada-color);
}
.ordemproducao-form .statusop-select.status-cancelada {
    border-color: var(--statusop-cancelada-color);
    color: var(--statusop-cancelada-color);
}
.ordemproducao-form .pasta .cores-title {
    flex: 0 0 100%;
    text-align: center;
    margin-bottom: 15px;
    position: relative;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}
.ordemproducao-form .pasta .cores-title::before {
    background-color: #8b8b8b;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: -2;
}
.ordemproducao-form .pasta .cores-title::after {
    background-color: #ffffff;
    display: block;
    content: "";
    height: 100%;
    left: calc(50% - 35px);
    position: absolute;
    top: 0;
    width: 70px;
    z-index: -1;
}
.ordemproducao-form .cores-container {
    background-color: #d5e4f5;
    border: solid 1px #76889c;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.ordemproducao-form .cores-container > .codigo {
    font-weight: bold;
}
.ordemproducao-form .cores-container > div:not(.codigo) {
    font-size: 14px;
}
.ordemproducao-form .cores-container > .menu, 
.ordemproducao-form .paradas-container > .form-row > .menu {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}

.ordemproducao-form .eventosdamaquina-container {
    padding: 0 !important;
    max-width: 300px;
}
.ordemproducao-form .eventosdamaquina-container button {
    font-size: 14px;
}
.ordemproducao-form .eventosdamaquina-container > .header {
    background-color: #616161;
    color: #fff;
    margin-bottom: 30px;
    padding: 20px;
}
.ordemproducao-form .eventosdamaquina-container > .body {
    border-radius: 5px;
    padding: 6px;
    margin-bottom: 280px;
}
.ordemproducao-form--naopermiteeditar .eventosdamaquina-container > .body {
    margin-bottom: 14px !important;
}
.ordemproducao-form .eventosdamaquina-container .evento {
    border: solid 1px #000;
    margin-bottom: 1px;
    padding: 14px;
}
.ordemproducao-form .eventosdamaquina-container .evento > .titulo {
    margin-bottom: 15px;
}
.ordemproducao-form .eventosdamaquina-container .evento.setup {
    border-color: #0012ff;
}
.ordemproducao-form .eventosdamaquina-container .evento.setup > .titulo {
    color: #0012ff;
}
.ordemproducao-form .eventosdamaquina-container .evento.producao {
    border-color: #177500;
}
.ordemproducao-form .eventosdamaquina-container .evento.producao > .titulo {
    color: #177500;
}
.ordemproducao-form .eventosdamaquina-container .evento.parada {
    border-color: #ff0000;
}
.ordemproducao-form .eventosdamaquina-container .evento.parada > .titulo {
    color: #ff0000;
}
.ordemproducao-form .eventosdamaquina-container .evento .label {
    display: inline-block;
    margin-right: 5px;
    text-align: right;
    width: 37px;
}
.ordemproducao-form .eventosdamaquina-container .evento .value {
    display: inline-block;
}
.ordemproducao-form .eventosdamaquina-container > .footer {
    display: flex;
    flex-direction: column;
}
.ordemproducao-form .eventosdamaquina-container > .footer > .title {
    margin-bottom: 10px;
    text-align: center;
}
.ordemproducao-form .eventosdamaquina-container > .footer > button + button {
    margin-top: 10px;
}
.ordemproducao-form .eventosdamaquina-container > .footer .addsetup {
    border-color: #0012ff;
    color:#0012ff;
}
.ordemproducao-form .eventosdamaquina-container > .footer .addproducao {
    border-color: #177500;
    color: #177500;
}
.ordemproducao-form .eventosdamaquina-container > .footer .addparada {
    border-color: #ff0000;
    color: #ff0000;
}
.ordemproducao-form .eventosdamaquina-container .disponibilidade-container {
    border: dotted 2px #62ff00;
    margin-bottom: 10px;
}
.ordemproducao-form .eventosdamaquina-container .disponibilidade-container .menu-container {
    margin-top: 15px;
}
.ordemproducao-form .eventosdamaquina-container .disponibilidade-container > .header {
    background-color: #aee58c;
    padding: 10px;
}
.ordemproducao-form .eventosdamaquina-container .disponibilidade-container > .header .title {
    font-weight: bold;
}
.ordemproducao-form .eventosdamaquina-container .disponibilidade-container > .header .label {
    display: inline-block;
    margin-right: 5px;
    text-align: right;
    width: 42px;
}
.ordemproducao-form .eventosdamaquina-container .disponibilidade-container > .header .value {
    display: inline-block;
}
.ordemproducao-form .eventosdamaquina-container .disponibilidade-container > .body {
    padding: 4px;
}
.ordemproducao-form .form-footeractions {
    background-color:#dadada;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: solid 1px #b3b3b3;
    bottom: 0;
    left: 0;
    padding: 10px 15px;
    position: fixed;
    right: 0;
    z-index: 1;
}
.ordemproducao-form .form-footeractions .container {
    text-align: left;
}

.opestampariaapontamentos-table .cor:not(:last-child) {
    margin-bottom: 15px;
}