.dashboard-filtro-container {
    border: solid 1px #9c9c9c;
    border-radius: 5px;
    margin-bottom: 3rem;
    margin-top: 100px;
    padding: 20px 20px 0;
}
.dashboard-filtro-container .tipo-relatorio {
    margin-left: -20px;
    margin-top: -70px;
    position: absolute;
}
.dashboard-filtro-container .tipo-relatorio .btn {
    border-color: var(--primary1-border-hover);
    color: var(--primary1-border-hover);
    font-weight: bold;
}
.dashboard-filtro-container .tipo-relatorio .btn.active {
    background-color: var(--primary1-hover);
    color: #fff;
}
.dashboard-filtro-container .label {
    display: inline-block;
    margin-bottom: .5rem;
}
.dashboard-filtro-container .clientes > .form-group {
    border: solid 1px #9c9c9c;
    border-radius: 5px;
    max-height: 92px;
    padding: 5px;
    padding-left: 10px;
    overflow: auto;
}
.dashboard-filtro-container .clientes .btn-link {
    padding-top: 0;
    padding-bottom: 0;
}
.dashboard-filtro-container .form-actions {
    align-self: flex-end;
    padding-bottom: 1rem;
    flex: 0;
}
.dashboard-filtro-container .form-actions > button {
    margin-right: 5px;
}
.dashboard-filtro-container .form-actions > button:last-of-type {
    margin-right: 0;
}

@media (min-width: 576px) {
    
}