#homeMenu .ico-title {
    font-size: 2em;
}
#homeMenu .iconlist {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
#homeMenu .iconlist li {
    position: relative;
    margin: 5px;
    width: 210px;
    cursor: pointer;
}
#homeMenu .iconlist li .icon-holder {
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid var(--primary1);
    color: var(--primary2);
    display: block;
    height: 150px;
    overflow: hidden;
    padding-bottom: 5px;
    position: relative;
    text-align: center;
    transition: all 0.2s linear 0s;
}
#homeMenu .iconlist li .icon-holder:hover {
    background: var(--primary1);
    color: #ffffff;
    text-decoration: none;
}
#homeMenu .iconlist li .icon-holder:hover .icon i {
    color: #ffffff;
}
#homeMenu .iconlist li .icon-holder .icon {
    padding: 20px;
    text-align: center;
}
#homeMenu .iconlist li .icon-holder .icon i {
    font-size: 3em;
    color: var(--primary2);
}
#homeMenu .iconlist li .icon-holder span {
    font-size: 16px;
    display: block;
    margin-top: 5px;
    border-radius: 3px;
}