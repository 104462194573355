.layout-unlogged #header {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.layout-unlogged #header .logo {
  margin: 20px auto;
}
.layout-unlogged #main {
  min-height: calc(100vh - 370px);
}
.layout-unlogged #footer {
  padding-bottom: 40px;
}
.layout-unlogged #footer {
  margin-top: 20px;
}
.layout-unlogged #footer #footer-content .col-links {
  text-align: center;
  flex-basis: 100%;
  max-width: 100%;
}
.layout-unlogged #footer #footer-content .col-logo {
  display: none;
}