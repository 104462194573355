:root {
    --statusop-aberta-color: #0000ff;
    --statusop-producao-color: #338c17;
    --statusop-fechada-color: #ed8d15;
    --statusop-finalizada-color: #525252;
    --statusop-cancelada-color: #ff0000;
}
.statusop-aberta-color {
    color: var(--statusop-aberta-color);
}
.statusop-producao-color {
    color: var(--statusop-producao-color);
}
.statusop-fechada-color {
    color: var(--statusop-fechada-color);
}
.statusop-finalizada-color {
    color: var(--statusop-finalizada-color);
}
.statusop-cancelada-color {
    color: var(--statusop-cancelada-color);
}

.statusop-badge {
    color: #fff;
    padding: 5px 10px;
}
.statusop-badge.statusop-badge-aberta {
    background-color: var(--statusop-aberta-color);
}
.statusop-badge.statusop-badge-finalizada {
    background-color: var(--statusop-finalizada-color);
}
.statusop-badge.statusop-badge-cancelada {
    background-color: var(--statusop-cancelada-color);
}