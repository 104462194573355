@media (min-width: 768px) {
    .page-calculadora .materia-prima-container .col-md-3 {
        flex-basis: calc(25% - 14px);
        max-width: calc(25% - 14px);
        margin-left: 7px;
        margin-right: 7px;
    }
}
.page-calculadora .materia-prima-container fieldset {
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 20px;
}
.page-calculadora .materia-prima-container fieldset > legend {
    color: #888;
    font-size: 16px;
    padding-right: 15px;
    width: auto;
}
.page-calculadora .materia-prima-container .resultado-container {
    background-color: #dcdcdc;
    display: flex;
    flex-direction: column;
}
.page-calculadora .materia-prima-container .resultado-container small {
    font-size: 15px;
}
.page-calculadora .materia-prima-container .resultado-container .conteudo {
    padding: 30px 0;
    place-content: center;
    place-items: center;
    text-align: center;
}
.page-calculadora .materia-prima-container .resultado-container .conteudo .custo {
    font-size: 50px;
}