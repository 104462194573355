.page-optintaprint #main {
    font-size: 10pt;
    -webkit-print-color-adjust: exact; 
}
.page-optintaprint #main .page-title {
    font-size: 16pt;
    font-weight: bold;
    text-align: center;
}
.page-optintaprint #main table {
    width: 100%;
}
.page-optintaprint #main table caption,
.page-optintaprint #main .caption {
    caption-side: top;
    color: #000;
    font-size: 14pt;
    font-weight: bold;
}
.page-optintaprint #main table th {
    background-color: #d8d8d8;
    border: solid 1px #000;
    padding: 3px 10px;
}
.page-optintaprint #main table tr {
    border: solid 1px #000;
}
.page-optintaprint #main table td {
    border: solid 1px #000;
    padding: 3px 10px;
}
.page-optintaprint #main table td.label {
    background-color: #d8d8d8;
    font-weight: bold;
}
.page-optintaprint #main .tintas-container .tintas {
    display: flex;
}
.page-optintaprint #main .tintas-container .tintas .tinta {
    border: solid 1px #000;
    box-sizing: border-box;
    margin-right: 0.5cm;
    padding-bottom: 35px;
    position: relative;
    width: 5cm;
}
.page-optintaprint #main .tintas-container .tintas .tinta .titulo,
.page-optintaprint #main .tintas-container .tintas .tinta .footer {
    background-color: #d8d8d8;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    padding: 5px 0;
}
.page-optintaprint #main .tintas-container .tintas .tinta .titulo {
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 1px;
}
.page-optintaprint #main .tintas-container .tintas .tinta .footer {
    bottom: 1px;
    font-size: 14px;
    left: 1px;
    margin-bottom: 0;
    position: absolute;
    right: 1px;
}
.page-optintaprint #main .tintas-container .tintas .tinta .materiaprima {
    border-bottom: dashed 1px #ccc;
    padding: 15px 10px;
}
.page-optintaprint #main .tintas-container .tintas .tinta div:nth-last-child(2) {
    border-bottom: none;
}